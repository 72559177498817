import React, { useState, useEffect, useRef, useLayoutEffect, useContext } from "react";
import "./App.css";

import arrow from "./images/arrowup.svg";
import { Col, Row, Carousel } from "react-bootstrap";

import customer1 from "./images/customers/man3.jpg";
import customer2 from "./images/customers/man4.jpg";
import bitcoin from "./images/bitcoin.svg";
import close from "./images/close.svg"
//import burger from "./images/burger-bar.svg";
import logo from "./images/logoNewLight.svg";

import "./styles/second.css";
import "./styles/whyWe.css";
import "./styles/testimonials.css";
import "./styles/return.css";
import "./styles/reliable.css";
import "./styles/navbar.css"
import "./styles/menu.css";
import "./styles/howToStart.css";
import "./styles/benefits.css";
import "./styles/banner.css";
import LeadForm from "./LeadForm";
import WhatsAppIcon from "./whatsapp/WhatsAppIcon";

const getWindowDimensions = function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
};

const bannerData = function slidesData() {
  return {
    // title: "****",
    firstBlock: "Быстро и эффективно поможем вернуть ваши деньги",
    secondBlock: "С какой бы ситуацией Вы не столкнулись Anoida поможет вернуть Ваши деньги. Мошеннические биржи, скам-кошельки, казино и брокеры, возврат криптовалюты - с этими и многими другими ситуациями Anoida успешно справляется уже много лет",
    btn: "Приступить к возврату",
    logo: logo,
  };
};

const benefitsData = function () {
  const colors = ["#c2a2ea", "#c7d2fb"];
  return {
    title: "Почему Anoida",
    descr:
      "",
    list: [
      {
        text: "Anoida не берет с клиентов никакой предоплаты или иных платежей за услуги пока клиент не получил свои деньги назад.",
        color: colors[0],
      },
      {
        text: "Никаких хлопот для Вас. Всю работу мы берем на себя",
        color: colors[1],
      },
      {
        text: "Низкий порог для входа. Мы беремся вернуть даже пару тысяч долларов",
        color: colors[0],
      },
      {
        text: "Мы добиваемся успеха в 96% случаев за которые беремся",
        color: colors[1],
      },
    ],
    subtitle: "",
    subdescr: ""
  };
};

const CommonContext = React.createContext({});
const ContextProvider = CommonContext.Provider;

const howTo = function howToStartData() {
  const colors = ["#c2a2ea", "#c7d2fb"];
  return {
    title: "Чаще всего мы помогаем:",  
    descr:
      "Для Вас сложившаяся ситуация является чем то необычным, но будьте уверены, что специалисты Anoida уже сталкивались с подобным случаем и успешно возвращали деньги клиентам.",
    list: [
      {
        text: "вернуть деньги клиентам бирж после того как брокер слил или заморозил депозит;",
        color: colors[0],
      },
      {
        text: "вывести выигрыш из онлайн-казино или букмекерской конторы;",
        color: colors[0],
      },
      {
        text: "спасти вклад в рухнувшую финансовую пирамиду или мошеннический инвестиционный фонд;",
        color: colors[1],
      },
      {
        text: "вернуть криптовалютную транзакцию отправленную мошенникам.",
        color: colors[0],
      },


    ],
    afterText: "",
  };
};

const reliableData = function reliableData() {
  return {
    title: "BATDU - a reliable partner, assistant and source of information about finance and investment",
    descr: "Get all the information you need to be successful and safe in the finance industry. If you already have a bad experience of interacting with scammers, we will help you get your money back.",
  };
}

const returnFundsData = function returnFundsData() {
  return {
    title: "Возвращаем криптовалюту",
    desc: "Возврат криптовалюты - это уникальная услуга предоставляемая Вам Anoida. До недавних пор потеря криптоалюты была необратима, но сейчас, используя последние достижения  сфере криптографии, финансов и IT, мы создали инструменты, позволящие вернуть криптоввалюту практически в любой ситуации. ",
    desc2: "Достаточно лишь подписать договор с Anoida и отправить заявку.",
    btn: "Получить бесплатную консультацию",
  };
}

const secondData = function secondData() {
  return {
    titleLeft: "Don't waste your time. It's time to get your money back!",
    titleRight: "Оставить заявку на бесплатную консультацию",
    textLeft: "Regardless of who stole your money, a fraudulent broker, bookmaker or casino, the time factor is very important. Experienced professionals of our company will take all the necessary measures and get your money back.",
    textRight:
      "",
    btn: "Отправить заявку",
  };
};

const ContactFormModal = ({ isModalVisible, onBackDropClick }) => {
  if (!isModalVisible) {
    return null;
  }

  return (
    <Modal onBackDropClick={onBackDropClick}>
      <div className="contactFormWrap">
        <div className="formTitle">
          <h2>Получить бесплатную консультацию</h2>
        </div>
        <LeadForm />
      </div>
    </Modal>
  );
};

const Modal = ({ onBackDropClick, children }) => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const wd = windowDimensions.width;
  const widthCon = () => {
    return wd > 1299 ? { width: "60%" } : { width: "100%" };
  };
  return (
    <div className="modalOverlay" onClick={onBackDropClick}>
      <div
        className="modalContainer"
        onClick={(e) => e.stopPropagation()}
        style={widthCon()}
      >
        <img className="closeForm" onClick={onBackDropClick} src={close} alt="close contact form" />
        {children}
      </div>
    </div>
  );
};

const Banner = ({  onBackDropClick }) => {
  const data = bannerData();
  const global = useContext(CommonContext);
  const containerRef = useRef();
  const sectionRef = useRef();
  const [dimensions, setDimensions] = useState({ height: 0 });

  useLayoutEffect(() => {
    if (containerRef.current && sectionRef.current) {
      setDimensions({       
        height: (sectionRef.current.offsetHeight - containerRef.current.offsetHeight)/2 
      });
    }
  }, []);
  const contStyle = () => {
    if (global.wd >= 1200) {
      return {
        margin: `${dimensions.height}px auto`
      };
    }
    return null;
  }
  return (
    <section className="banner" id="home" ref={sectionRef}>
      <div className="bannerInner">
        <div className="container" ref={containerRef} style={contStyle()}>
              <div className="d-block align-items-end d-md-flex">
                  <div className="bannerLogo">
                    <img src={data.logo} alt="logo" />
                  </div>
                  <div>
                    <h1>{data.firstBlock}</h1>
                  </div>
              </div>
              <div className="bannerText">
                {data.secondBlock ? (
                  <p className="banner-descr">{data.secondBlock}</p>
                ) : null}
              </div>          
          <div className="banner-btn">
            <button
              className="btn-primary"
              onClick={() => {
                onBackDropClick();
              }}
            >
              {data.btn}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

const Benefits = () => {
  const [show, doShow] = useState(false);
  const ourRef = useRef(null);

  useLayoutEffect(() => {
    const topPos = (element) => element.getBoundingClientRect().top;
    const div1Pos = topPos(ourRef.current);

    const onScroll = () => {
      const scrollPos = window.scrollY + window.innerHeight;

      if (div1Pos < scrollPos) {
        doShow(true);
      } else {
        doShow(false);
      }
    };

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const data = benefitsData();

  return (
    <section
      className={`benefits common__section ${show && "active"}`}
      id="benefits"
      ref={ourRef}
    >
      <div className="innerSection">
        <div className="imageBlock"></div>
        <div className="textBlock">
          <h2>{data.title}</h2>
          {/* <p>{data.descr}</p> */}
          <ul className="list">
            {data.list.map((e, i) => (
              <li key={i} style={{ borderColor: e.color }}>
                {e.text}
              </li>
            ))}
          </ul>
          {/* <h4>{data.subtitle}</h4>
        <p>{data.subdescr}</p> */}
        </div>
      </div>
    </section>
  );
};

const HowToStart = () => {
  const [show, doShow] = useState(false);
  const ourRef = useRef(null);
  const global = useContext(CommonContext);
  const colors = ["#c2a2ea", "#c7d2fb"];
  useLayoutEffect(() => {
    const topPos = (element) => element.getBoundingClientRect().top;
    const div1Pos = topPos(ourRef.current);

    const onScroll = () => {
      const scrollPos = window.scrollY + window.innerHeight;

      if (div1Pos < scrollPos) {
        doShow(true);
      } else {
        doShow(false);
      }
    };

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const data = howTo();
  const liStyle = (i) => {
    let wd = 45;
    let color = i === 0 || i===3 ? colors[0] : colors[1];
    if (global.wd <= 450) {
      wd = 100;
      color = i % 2 === 0 ? colors[0] : colors[1];
    }
    if (global.wd >= 800 && global.hd <= 450) {
      wd = 48;
    }
    const del = i === 0 ? 0.25 : i;
    return {
      borderColor: color,
      width: `${wd}%`,
      animationDelay: `${del}s`,
    };
  };
  return (
    <section
      className={`howToStart common__section ${show && "active"}`}
      id="howToStart"
      ref={ourRef}
    >
      <div className="container">
        <h2>{data.title}</h2>
        <p>{data.descr}</p>
        <ul className="list">
          {data.list.map((e, i) => (
            <li key={i} style={liStyle(i)}>
              {e.text}
              <p className="number">{i + 1}</p>
            </li>
          ))}
        </ul>
        {/* <p className="afterText">{data.afterText}</p>        */}
      </div>
    </section>
  );
};

/* const Menu = ({toggleClick, burgerClick}) => {
  const classNameImg = () => {
    switch (burgerClick) {
      case 0:
        return "burgerImg";
      case 1:
        return "burgerImg rotate";
      case 2:
        return "burgerImg unrotate";
      default:
        return "burgerImg";
    }
  };
  return (
    <>
      <div
        className="menu"
        onClick={() => {
          toggleClick();
        }}
      >
        <img src={burger} alt="burger" className={classNameImg()} />
      </div>
      <MenuInner burgerClick={burgerClick} openClose={toggleClick} />
    </>
  );
}; */

const MenuInner = ({ openClose, burgerClick }) => {
  const classNameNav =()=> {
    return burgerClick === 1 ? "nav container show" : "nav container hidden";
  }
  return burgerClick !== 0 ? (
    <div className={classNameNav()} >
      <a href="#home" onClick={openClose} className="nav-link">Top</a>      
      <a href="#second" onClick={openClose} className="nav-link">Contact Us</a>       
      <a href="#whywe" onClick={openClose} className="nav-link">What We Can Do</a>
      <a href="#howToStart" onClick={openClose} className="nav-link">Start Steps</a> 
      <a href="#reliable" onClick={openClose} className="nav-link">Reliable Partner</a>
      <a href="#testimonials" onClick={openClose} className="nav-link">Reviews</a>
      <img src={close} alt="close menu" className="closeForm" onClick={openClose} />
    </div>
  ): null;
};

const Reliable = () => {

  const data = reliableData();
  const [show, doShow] = useState(false);
  const ourRef = useRef(null)
  useLayoutEffect(() => {
    const topPos = (element) => element.getBoundingClientRect().top;
    const div1Pos = topPos(ourRef.current)

    const onScroll = () => {
      const scrollPos = window.scrollY + window.innerHeight;
      
      if (div1Pos < scrollPos) {
        doShow(true);
      } else {
        doShow(false);
      }
    };

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (<section className={`reliable common__section ${show && 'active'}`} id="reliable" ref={ourRef}>

    <div className="container">
      <h2>{data.title}</h2>
      <p className="descr">{data.descr}</p>
    </div>
  </section >);
}

const ReturnFunds = ({ onBackDropClick }) => {
  const returnFunds = returnFundsData();
  const [show, doShow] = useState(false);
  const ourRef = useRef(null);
  useLayoutEffect(() => {
    const topPos = (element) => element.getBoundingClientRect().top;
    const div1Pos = topPos(ourRef.current);

    const onScroll = () => {
      const scrollPos = window.scrollY + window.innerHeight;

      if (div1Pos < scrollPos) {
        doShow(true);
      } else {
        doShow(false);
      }
    };

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  return (
    <section
      className={`common__section returnFunds ${show && "active"}`}
      id="return-funds"
      ref={ourRef}
    >
      {/* <img src={returnBg} alt="section background"/> */}
      <div className="container">
        <div className="returnFunds__inner">
          <div className="returnFundsIMG">
            <img src={bitcoin} alt="" />
          </div>
          <div className="textBlock">
            <h3 className="my-4">{returnFunds.title}</h3>
            <p>{returnFunds.desc}</p>
            <p>{returnFunds.desc2}</p>
          <button
            className="btn-primary"
            onClick={() => {
              onBackDropClick();
            }}
          >
            {returnFunds.btn}
          </button>
          </div>
        </div>
      </div>
    </section>
  );
};

const Second = () => {
  const data = secondData();
  const [show, doShow] = useState(false);
  const ourRef = useRef(null);
  useLayoutEffect(() => {
    const topPos = (element) => element.getBoundingClientRect().top;
    const div1Pos = topPos(ourRef.current);

    const onScroll = () => {
      const scrollPos = window.scrollY + window.innerHeight;

      if (div1Pos < scrollPos) {
        doShow(true);
      } else {
        doShow(false);
      }
    };

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  return (
    <section
      className={`second common__section ${show && "active"}`}
      id="second"
      ref={ourRef}
    >
      <div className="container">
        <div className="rightBlock blocks">
          <h2>{data.titleRight}</h2>
          <p>{data.textRight}</p>
          <LeadForm />
        </div>
      </div>
    </section>
  );
};

const testimonialData = function testimonialData() {
  return {
    title: "Company reviews",
    desc: "Testimonials of clients whom we have already helped to return money",
    list: [
      {
        img: customer1,
        author: "Jacob Swat",
        text: "Unfortunately, I contacted a dishonest broker. With swindlers, if we call a spade a spade. I have lost a fairly significant amount of money. I am very glad that I trusted BATDU and was able, although not immediately, to get my money back. Thank you.",
      },
      {
        img: customer2,
        author: "Michael Court",
        text: "I thought it was literally impossible, but the BATDU specialists were able to return my money to me. I lost a lot of time while arguing with the broker and everyone said that nothing could be done, and the professionals BATDU just returned my money to me. My compliments, there is no other way to say.",
      },
    ],
  };
}

const Testimonials = () => {
  const [indexTestimonials, setIndexTestimonials] = useState(0);

  const handleTestimonials = (selectedIndex, e) => {
    setIndexTestimonials(selectedIndex);
  };
  const testimonials = testimonialData();
  const renderTestimonials = testimonials.list.map((e, i) => {
    return (
      <Carousel.Item key={i}>
        <div className="slide-inner">
          <Row>
            <Col xs="12" md="12" lg="2" className="user-avatar text-center">
              <img src={e.img} alt="tester avatar" className="tester-avatar" />
              <h5>{e.author}</h5>
            </Col>
            <Col
              xs="12"
              md="12"
              lg="10"
              className="align-self-center testimonial-content"
            >
              <p className="p-3">{e.text}</p>
            </Col>
          </Row>
        </div>
      </Carousel.Item>
    );
  });
  return (
    <section
      className="tesimonials__external common__section"
      id="testimonials"
    >
      <div className="container">
        <h3>{testimonials.title}</h3>
        {testimonials.desc !== "" ? <p className="descr">{testimonials.desc}</p> : null}

        <Carousel
          activeIndex={indexTestimonials}
          interval={5000}
          indicators={false}
          onSelect={handleTestimonials}
        >
          {renderTestimonials}
        </Carousel>
      </div>

      {/* <div className="waveNew waveTop"></div>
        <div className="waveNew waveMid"></div>
        <div className="waveNew waveBot"></div> */}
      <div className="light2 x11"></div>
      <div className="light2 x22"></div>
      <div className="light2 x31"></div>
      <div className="light2 x41"></div>
      <div className="light2 x51"></div>
      <div className="light2 x61"></div>
      <div className="light2 x71"></div>
      <div className="light2 x81"></div>
      <div className="light2 x91"></div>
    </section>
  );
};

const whyWe = function () {
  const colors = ["#c2a2ea", "#c7d2fb"];
  return {
    title: "Как Anoida помогает вернуть Ваши деньги",
    descr:
      "Все что должно быть сделано Anoida осуществляет практически без участия клиента. Вам достаточно обратиться в нашу компанию и подождать несколько дней. Для того, чтобы Вы получили назад свои деньги надо пройти несколько этапов:",
    list: [
      {
        text: "Оставьте на нашем сайте заявку на получение бесплатной консультации",
        color: colors[0],
      },
      {
        text: "Заключите с Anoida договор на оказание услуги по возврату средств",
        color: colors[1],
      },
      {
        text: "Anoida готовит все необходимое для инициации процесса возврата Ваших денег",
        color: colors[0],
      },
      {
        text: "Мы возвращаем Ваши деньги используя все доступные судебные и несудебные инструменты.",
        color: colors[1],
      },
      {
        text: "Вы получаете свои деньги не счет и только после этого Anoida получает свое вознаграждение",
        color: colors[0],
      },
    ],
    subtitle: "",
    subdescr: ""
  };
};

const WhyWe = ({ onBackDropClick }) => {
  const [show, doShow] = useState(false);
  const ourRef = useRef(null);

  useLayoutEffect(() => {
    const topPos = (element) => element.getBoundingClientRect().top;
    const div1Pos = topPos(ourRef.current);

    const onScroll = () => {
      const scrollPos = window.scrollY + window.innerHeight;

      if (div1Pos < scrollPos) {
        doShow(true);
      } else {
        doShow(false);
      }
    };

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const data = whyWe();

  return (
    <section
      className={`whywe common__section ${show && "active"}`}
      id="whywe"
      ref={ourRef}
    >
      <div className="innerSection">
        <div className="textBlock">
          <h2>{data.title}</h2>
          <p>{data.descr}</p>
          <ul className="list">
            {data.list.map((e, i) => (
              <li key={i} style={{ borderColor: e.color }}>
                {e.text}
              </li>
            ))}
          </ul>
          <div className="banner-btn">
            <button
              className="btn-primary"
              onClick={() => {
                onBackDropClick();
              }}
            >
              Получить бесплатную консультацию
            </button>
          </div>
        </div>
        <div className="imageBlock"></div>
      </div>
    </section>
  );
};

const App = () => {
  const [isModalVisible, setModalVisible] = useState(false);
  const toggleModal = () => {
    setModalVisible((wasModalVisible) => !wasModalVisible);
  };
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const wd = windowDimensions.width;
  const hd = windowDimensions.height;
  const global = {
    wd: wd,
    hd: hd,
  };

  const topRef = useRef();
  function handleBackClick() {
    topRef.current.scrollIntoView({ behavior: "smooth" });
  }
  const [show, doShow] = useState("hidden");
  useLayoutEffect(() => {
    const onScroll = () => {
      const scrollPos = window.scrollY;
      if (scrollPos > 100) {
        doShow("visible");
      } else {
        doShow("hidden");
      }
    };

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  return (
    <ContextProvider value={global}>
      <div className="App" ref={topRef}>
        <div className="bannerTop">
          <Banner onBackDropClick={toggleModal} />
        </div>
        <HowToStart />
        <WhyWe onBackDropClick={toggleModal} />
        <Benefits />
        <ReturnFunds onBackDropClick={toggleModal} />
        <Second />
        <div className={`toTopButton ${show}`} onClick={handleBackClick}>
          <img src={arrow} alt="" />
        </div>
        <ContactFormModal
          isModalVisible={isModalVisible}
          onBackDropClick={toggleModal}
        />
      </div>
      <WhatsAppIcon />
    </ContextProvider>
  );
};

export default App;
